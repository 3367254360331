import styled, { css } from "styled-components";

import {
  Purple,
  Gray,
  White,
  Black,
  LightGray,
  Gray2,
  White07,
  Purple07,
  Black03,
  Black05,
} from "../colors";

export const D1 = styled.h1`
  font-family: "Protest Strike", serif;
  font-weight: 900;
  font-size: 5rem;
  font-display: swap;
  font-style: normal;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.LightGray && Black03};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};

  @media (max-width: 950px) {
    font-size: 6rem;
  }

  @media (max-width: 600px) {
    font-size: 4rem;
  }

  @media (max-width: 400px) {
    font-size: 2.5rem;
  }
`;

export const D2 = styled.h1`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 3.5rem;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.LightGray && LightGray};
  color: ${(props) => props.Gray && Gray2};
`;

export const D3 = styled.h1`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 2.7rem;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.LightGray && LightGray};
  color: ${(props) => props.Gray && Gray2};
`;

export const H1 = styled.h1`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const H2 = styled.h2`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};=
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const H3 = styled.h3`
  font-family: "Satoshi-Medium";
  font-weight: 500;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.LightGray && LightGray};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};

  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const H4 = styled.h4`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  font-size: 20px;
  border-radius: 200px;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.LightGray && Purple07};
`;

export const AltH4 = styled.h4`
  font-family: "Helvetica Neue";
  font-weight: 700;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};=
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const H5 = styled.h5`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-display: swap;
  font-size: 15px;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.LightGray && White07};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.Gray && Gray2};
`;

export const H6 = styled.h6`
  font-family: "Satoshi-Variable";
  font-weight: 900;
  font-size: 14px;
  font-display: swap;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const TextXs = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  font-display: swap;
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.color};
`;

export const TextXsItalic = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-size: 11px;
  line-height: 19px;
  font-display: swap;
  font-style: italic;
  margin: 0;
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.color};
`;

export const TextXsNorm = styled.h6`
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 11px;
  line-height: 19px;
  font-display: swap;
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.color};
`;

export const TextSm = styled.h6`
  font-family: "Satoshi-Medium";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 120%;
  font-display: swap;
  margin: 0;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.lightGray && LightGray};
`;

export const TextSmNorm = styled.h6`
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  font-display: swap;
  margin: 0;
  color: ${(props) => props.color};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.lightGray && LightGray};
`;

export const Paragraph = styled.p`
  font-family: "Satoshi-Regular";
  ${(props) =>
    props.footer &&
    css`
      margin-bottom: 5px;
    `}
  font-display: swap;
  font-size: 17px;
  font-weight: 300;
  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
  color: ${(props) => props.LightGray && White07};
`;

export const BlogTitle = styled.h1`
  font-family: "Satoshi-Regular";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  color: ${Black};
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const BlogSubTitle = styled.h2`
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Gray2};
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const BlogDate = styled.h3`
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${Gray2};
  position: absolute;
  bottom: 0;
  text-decoration: none;
  font-display: swap;

  color: ${(props) => props.color};
  color: ${(props) => props.Gray && Gray};
  color: ${(props) => props.White && White};
  color: ${(props) => props.Purple && Purple};
  color: ${(props) => props.Black && Black};
`;

export const Span = styled.span`
  font-display: swap;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.Norm && "400"};
`;

export const VerificationMessage = styled(TextSm)`
  margin-top: 10px;
  font-weight: 800;
  color: ${Black05};
`;
