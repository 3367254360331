export const Purple = "#5478F0";
export const Gray = "#565656";
export const LightGray = "#EFF0F3";
export const White = "#fff";
export const Black = "#000";
export const LightBlue = "#70CDF9";
export const Yellow = "#FEE03C";

export const Black2 = "#2F3032";
export const Black005 = "rgba(0, 0, 0, 0.05)";
export const Black09 = "rgba(0, 0, 0, 0.9)";
export const Black07 = "rgba(0, 0, 0, 0.7)";
export const Black05 = "rgba(0, 0, 0, 0.5)";
export const Black03 = "rgba(0, 0, 0, 0.3)";
export const Black01 = "rgba(0, 0, 0, 0.1)";
export const Gray2 = "#58667E";
export const LightGray2 = "#E2E2E3";

export const White01 = "rgba(255, 255, 255, 0.1)";
export const White03 = "rgba(255, 255, 255, 0.3)";
export const White05 = "rgba(255, 255, 255, 0.5)";
export const White07 = "rgba(255, 255, 255, 0.7)";
export const White08 = "rgba(255, 255, 255, 0.8)";

export const Purple005 = "rgba(84, 120, 240, 0.05)";
export const Purple01 = "rgba(84, 120, 240, 0.1)";
export const Purple02 = "rgba(84, 120, 240, 0.2)";
export const Purple03 = "rgba(84, 120, 240, 0.3)";
export const Purple05 = "rgba(84, 120, 240, 0.5)";
export const Purple07 = "rgba(84, 120, 240, 0.7)";
export const Purple09 = "rgba(84, 120, 240, 0.9)";

export const LightBlue005 = "rgba(112, 205, 249, 0.05)";
export const LightBlue01 = "rgba(112, 205, 249, 0.1)";
export const LightBlue03 = "rgba(112, 205, 249, 0.3)";
export const LightBlue05 = "rgba(112, 205, 249, 0.5)";
export const LightBlue07 = "rgba(112, 205, 249, 0.7)";

export const LightPurple = "#fbfafd";

export const Red = "#cc0000";
