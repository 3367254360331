import { Col, Container, Row } from "react-bootstrap";
import Layout from "../../components/layout/layout";
import { H3, H5, H6, TextSm } from "../../components/theme/text/Headings";
import {
  BreakdownBody,
  BreakdownBottom,
  BreakdownContainer,
  BreakdownHeader,
  QuestionBox,
} from "../../components/theme/QuizBox";
import Axis from "../../components/theme/axis";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../services/firebase.config";
import styled from "styled-components";
import { Black } from "../../components/theme/colors";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SecPic } from "./LandingFD";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { ButtonBlack } from "../../components/theme/Button";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Black};
`;

const ResultsFD = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [firstDateQuizId, setFirstDateQuizId] = useState("");

  const [firstDateQuizId2, setFirstDateQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");
  const [username2, setUsername2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");

        setFirstDateQuizId(data.firstDateQuizId);
      }
    };

    fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        const sharedDoc = await getDoc(sharedDocRef);

        if (sharedDoc.data().senderUid === currentUser.uid) {
          setFirstDateQuizId2(sharedDoc.data().receiverQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().receiverUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        } else if (sharedDoc.data().senderUid !== currentUser.uid) {
          setFirstDateQuizId2(sharedDoc.data().senderQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().senderUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        }
      }
    };
    getSharedCollection();
  }, [sharedId, currentUser.uid]);

  return (
    <Layout background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}>
      <Container>
        <Row className="mt-30">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <QuestionBox style={{ flexDirection: "column" }}>
              <TextSm>quiz results</TextSm>
              <H5 className="mb-20">what's your perfect first date?</H5>

              {!sharedId ? (
                <>
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </>
              ) : (
                <div className="display-flex">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <CompareArrowsIcon className="mt-15" />
                  {photoURL2 ? (
                    <ProPic src={photoURL2} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName2 ? displayName2[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </div>
              )}
            </QuestionBox>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <BreakdownContainer>
              <BreakdownHeader>
                <H6 className="mb-0">
                  score breakdown:{" "}
                  {username ? `@${username}` : `${displayName}`}{" "}
                  {sharedId && (
                    <> {username2 ? `+ @${username2}` : `+ ${displayName2}`}</>
                  )}
                </H6>
              </BreakdownHeader>
              <BreakdownBody>
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Daytime"
                  rightLabel="Nighttime"
                  leftDescription="Prefers dates during the day."
                  rightDescription="Prefers dates during the night."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="night"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Intimate"
                  rightLabel="Adventurous"
                  leftDescription="Prefers a one-on-one vibe."
                  rightDescription="Likes being amongst more people."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="adventurous"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Spontaneous"
                  rightLabel="Planner"
                  leftDescription="Likes to go with the flow."
                  rightDescription="Prefers to plan the date."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="planner"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Reserved"
                  rightLabel="Open"
                  leftDescription="Likes to take their time."
                  rightDescription="Most open to connection."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="instantSpark"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Hard to get"
                  rightLabel="Simp"
                  leftDescription="More reserved, takes time to let someone in. "
                  rightDescription="Willing to do anything for affection"
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="simp"
                />
                <Axis
                  quizName="firstDate"
                  quizId={firstDateQuizId}
                  senderQuizId={firstDateQuizId2}
                  leftLabel="Thrifty"
                  rightLabel="Boujee"
                  leftDescription="Down to slum it."
                  rightDescription="Likes the finer things."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="boujee"
                />
              </BreakdownBody>
            </BreakdownContainer>
          </Col>
        </Row>
        {(currentUser.isAnonymous || !currentUser) && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
              className="text-align-center"
            >
              <BreakdownBottom className="justify-content-center display-column">
                <H6 className="mb-20">👇 create an account 👇</H6>
                <ButtonBlack
                  className="mt-0"
                  width="80%"
                  onClick={() =>
                    navigate("/login", { state: { linkAnonymous: true } })
                  }
                >
                  save your answers
                </ButtonBlack>
              </BreakdownBottom>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ResultsFD;
