import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import styled from "styled-components";

import Layout from "../components/layout/layout";
import { Col, Container, Row } from "react-bootstrap";
import { H6 } from "../components/theme/text/Headings";
import QuizBox from "../components/theme/QuizBox";
import {
  GradientButton,
  ButtonOutlineBlack,
  ButtonBlack,
} from "../components/theme/Button";

import { auth, db } from "../services/firebase.config";

const ButtonBox = styled.div`
  background: ${({ gradient }) => gradient || "#fff5ef"};
  border-radius: 10px;
  padding: 25px 10px;
  margin-top: 20px;
  width: 100%;
`;

const Home = () => {
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [startQuizURL, setStartQuizURL] = useState(null);
  const [sendQuizURL, setSendQuizURL] = useState(null);
  const [quizGradient, setQuizGradient] = useState(null);

  const [fDCompleted, setFDCompleted] = useState(false);
  const [pACompleted, setPACompleted] = useState(false);

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const fdQuizId = profileDoc.data().firstDateQuizId;
        const paQuizId = profileDoc.data().politicalQuizId;

        if (fdQuizId) {
          setSendQuizURL(`/first-date?from=${fdQuizId}`);

          const docRef = doc(db, "first-date-quiz", fdQuizId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists() && docSnap.data().status === "completed") {
            setFDCompleted(true);
          }
        }

        if (paQuizId) {
          setSendQuizURL(`/political-alignment?from=${paQuizId}`);

          const docRef = doc(db, "political-alignment-quiz", paQuizId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists() && docSnap.data().status === "completed") {
            setPACompleted(true);
          }
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  const copyToClipboard = (URL) => {
    navigator.clipboard
      .writeText(URL)
      .then(() => alert("Link copied to clipboard!"))
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const handleSelectQuiz = (quiz, url, gradient) => {
    if (selectedQuiz === quiz) {
      setSelectedQuiz(null);
      setStartQuizURL(null);
      setQuizGradient(null);
    } else {
      setSelectedQuiz(quiz);
      setStartQuizURL(url);
      setQuizGradient(gradient);
    }
  };

  const comingSoonQuizzes = ["kink level", "flag check"];

  return (
    <Layout>
      <Container>
        <Row className="mt-30">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ offset: 1, span: 10 }}
            className="text-align-center"
          >
            <H6 className="mb-20">first, choose a quiz</H6>
            <Row>
              <Col>
                <QuizBox
                  background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}
                  selected={selectedQuiz === "first date"}
                  anyselected={selectedQuiz !== null}
                  onClick={() =>
                    handleSelectQuiz(
                      "first date",
                      "/first-date-question-1",
                      "linear-gradient(90deg, #fc4a1a, #f7b733)"
                    )
                  }
                >
                  <H6>first date feels ✨</H6>
                </QuizBox>
              </Col>
              <Col>
                <QuizBox
                  background={"linear-gradient(90deg, #00F260, #0575E6)"}
                  selected={selectedQuiz === "political-alignment"}
                  anyselected={selectedQuiz !== null}
                  onClick={() =>
                    handleSelectQuiz(
                      "political-alignment",
                      "/political-alignment-question-1",
                      "linear-gradient(90deg, #00F260, #0575E6)"
                    )
                  }
                >
                  <H6>Political Alignment 🏛️</H6>
                </QuizBox>
              </Col>
            </Row>
            <Row>
              <Col>
                <QuizBox
                  background={"linear-gradient(90deg, #FC5C7D, #6A82FB)"}
                  selected={selectedQuiz === "flag check"}
                  anyselected={selectedQuiz !== null}
                  onClick={() =>
                    handleSelectQuiz(
                      "flag check",
                      null,
                      "linear-gradient(90deg, #FC5C7D, #6A82FB)"
                    )
                  }
                >
                  <H6>
                    devil's in the <br />
                    details 🚩
                  </H6>
                </QuizBox>
              </Col>
              <Col>
                <QuizBox
                  background={"linear-gradient(90deg, #ff9966, #ff5e62)"}
                  selected={selectedQuiz === "kink level"}
                  anyselected={selectedQuiz !== null}
                  onClick={() =>
                    handleSelectQuiz(
                      "kink level",
                      null,
                      "linear-gradient(90deg, #ff9966, #ff5e62)"
                    )
                  }
                >
                  <H6>kink level 🌶️</H6>
                </QuizBox>
              </Col>
            </Row>

            {selectedQuiz && (
              <Row>
                {comingSoonQuizzes.includes(selectedQuiz) ? (
                  <ButtonBox gradient={quizGradient}>
                    <H6 style={{ color: "#fff" }}>coming soon!</H6>
                  </ButtonBox>
                ) : (
                  <>
                    <ButtonBox gradient="#fff5ef">
                      <H6 className="mb-20">second, take the quiz</H6>
                      <div className="display-flex display-column">
                        <Link to={startQuizURL}>
                          <ButtonOutlineBlack width="200px">
                            {(selectedQuiz === "first date" && fDCompleted) ||
                            (selectedQuiz === "political-alignment" &&
                              pACompleted)
                              ? "re-take quiz"
                              : "take quiz"}
                          </ButtonOutlineBlack>
                        </Link>

                        {selectedQuiz === "first date" && fDCompleted && (
                          <Link to="/first-date/results">
                            <ButtonBlack width="200px" className="mt-10">
                              my results
                            </ButtonBlack>
                          </Link>
                        )}

                        {selectedQuiz === "political-alignment" &&
                          pACompleted && (
                            <Link to="/political-alignment/results">
                              <ButtonBlack width="200px" className="mt-10">
                                my results
                              </ButtonBlack>
                            </Link>
                          )}
                      </div>
                    </ButtonBox>

                    <ButtonBox gradient="#fff5ef">
                      <H6 className="mb-20">third, share with pookie</H6>
                      <GradientButton
                        width="200px"
                        background={quizGradient}
                        onClick={() =>
                          copyToClipboard(
                            `${window.location.origin}${sendQuizURL}`
                          )
                        }
                      >
                        share quiz
                      </GradientButton>
                    </ButtonBox>
                  </>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
