import styled from "styled-components";
import {
  Black,
  Black01,
  Black05,
  Black07,
  Gray,
  Purple,
  Purple03,
  Purple07,
  Purple09,
  White,
  White08,
} from "./colors";

export const ButtonLightPurple = styled.button`
  background-color: ${Purple03};
  border: none;
  color: ${Black};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Purple07};
  }
`;

export const ButtonLightPurpleSm = styled.button`
  background-color: ${Purple03};
  border: none;
  color: ${Gray};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 13px;
  margin-right: 10px;
  border-radius: 5px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Purple07};
  }
`;

export const ButtonWhite = styled.button`
  margin-right: 10px;
  background-color: ${White};
  border: none;
  border-radius: 200px;
  color: ${Black};
  font-family: "Roboto", sans-serif;
  padding: 10px 24px;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;

  &:hover {
    background-color: ${White08};
  }
`;

export const ButtonWhiteSm = styled.button`
  margin-right: 10px;
  background-color: ${White};
  border: none;
  border-radius: 200px;
  color: ${Black};
  font-family: "Roboto", sans-serif;
  padding: 10px 24px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    background-color: ${White08};
  }
`;

export const ButtonDisabled = styled.button`
  background-color: ${Black01};
  border: none;
  color: ${Black05};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: default !important;
  width: ${(props) => props.width};
`;

export const ButtonPaywall = styled.button`
  background-color: ${(props) => props.bgcolor};
  border: none;
  color: ${(props) => props.color};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.24);
  width: ${(props) => props.width};
  &:hover {
    background-color: ${(props) => props.bgcolorhover};
  }
`;

export const ButtonPurpleSm = styled.button`
  background-color: ${Purple};
  border: none;
  color: ${White};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 13px;
  margin-right: 10px;
  border-radius: 5px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Purple09};
  }
`;

export const ButtonPreviewXs = styled.button`
  background-color: ${(props) => props.bgcolor};
  border: none;
  color: ${(props) => props.color};
  padding: 7px 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 10px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.24);
  &:hover {
    background-color: ${(props) => props.bgcolorhover};
  }
`;

export const ButtonBlack = styled.button`
  background-color: ${Black};
  color: ${White};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 200px;
  border: none;
  width: ${(props) => props.width};
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  &:hover {
    background-color: ${Black07};
  }
`;

export const ButtonTransparent = styled.button`
  background-color: transparent;
  border: none;
  color: ${White};
  padding: 10px 24px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-right: 10px;
  font-size: 16px;
  border-radius: 200px;
  width: ${(props) => props.width};
  &:hover {
    color: ${Purple09};
  }
`;

export const ButtonBlackSm = styled.button`
  background-color: ${Black};
  border: none;
  color: ${White};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 13px;
  margin-right: 10px;
  border-radius: 5px;
  width: ${(props) => props.width};
  &:hover {
    background-color: ${Black};
  }
`;

export const ButtonOutline = styled.button`
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 16px;
  border-radius: 200px;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color};
  color: ${(props) => props.textOverride};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  -moz-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  &:hover {
    color: ${White};
    color: ${(props) => props.textOverride};
    background: ${(props) => props.color};
  }
`;

export const ButtonOutlineSm = styled.button`
  padding: 7px 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  color: ${(props) => props.color};
  color: ${(props) => props.textOverride};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  -moz-box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  box-shadow: inset 0px 0px 0px 2px ${(props) => props.color};
  &:hover {
    color: ${White};
    color: ${(props) => props.textOverride};
    background: ${(props) => props.color};
  }
`;

export const ButtonOutlineBlack = styled.button`
  padding: 10px 24px;
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  border-radius: 200px;
  color: ${Black};
  width: ${(props) => props.width};
  -webkit-box-shadow: inset 0px 0px 0px 2px ${Black};
  -moz-box-shadow: inset 0px 0px 0px 2px ${Black};
  box-shadow: inset 0px 0px 0px 2px ${Black};
  &:hover {
    color: ${White};
    background: ${Black07};
    -webkit-box-shadow: inset 0px 0px 0px 2px ${Black07};
    -moz-box-shadow: inset 0px 0px 0px 2px ${Black07};
    box-shadow: inset 0px 0px 0px 2px ${Black07};
  }
`;

export const GradientButton = styled.button`
  background: ${(props) => props.background || Black};
  color: ${White};
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 200px;
  border: none;
  width: ${(props) => props.width};
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  &:hover {
    background-color: ${Black07};
  }
`;

export const GradientButtonXs = styled.button`
  background: linear-gradient(90deg, #7f00ff, #e100ff);
  padding: 10px 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  color: ${White};
  width: ${(props) => props.width};
`;
