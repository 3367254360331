import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";

import styled from "styled-components";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { TextField } from "@mui/material";

import Layout from "../../components/layout/layout";
import { H3, H5, H6, TextSm } from "../../components/theme/text/Headings";
import { QuestionBox } from "../../components/theme/QuizBox";
import { Black, Black07, White } from "../../components/theme/colors";
import { ButtonBlack } from "../../components/theme/Button";

import { auth, db } from "../../services/firebase.config";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Black};
`;

export const SecPic = styled.div`
  height: 50px;
  width: 50px;
  font-size: 20px;
  border-radius: 50px;
  border: 3px solid ${Black};
  background-color: ${Black07};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${White};
`;

const LandingFD = () => {
  const currentUser = auth.currentUser;

  //sender info
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [senderUid, setSenderUid] = useState("");

  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoto, setReceiverPhoto] = useState("");
  const [searchParams] = useSearchParams();

  const fromQuizId = searchParams.get("from");

  const navigate = useNavigate();

  //send data when submitting name
  const authenticateAndSetDisplayName = async () => {
    try {
      //create new user
      const { user } = await signInAnonymously(auth);
      const userDocRef = doc(db, "public-profiles", user.uid);

      await setDoc(
        userDocRef,
        {
          uid: user.uid,
          displayName: receiverName,
        },
        { merge: true }
      );

      //create new shared file
      const newSharedRef = await addDoc(collection(db, "shared-quizzes"), {
        quiz: "first-date",
        receiverUid: user.uid,
        senderUid: senderUid,
        senderQuizId: fromQuizId,
      });

      navigate(`/first-date-question-1?shared=${newSharedRef.id}`);
    } catch (error) {
      console.error("Error with anonymous sign-in or profile update:", error);
    }
  };

  useEffect(() => {
    const fetchLoggedIn = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "public-profiles", currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc) {
          setReceiverName(userDoc.data().displayName);
          setReceiverPhoto(userDoc.data().photoURL);
        }
      }
    };

    fetchLoggedIn();
  }, [currentUser]);

  //fetch sender data
  useEffect(() => {
    const fetchSenderData = async () => {
      if (fromQuizId) {
        const quizDocRef = doc(db, "first-date-quiz", fromQuizId);
        const quizDoc = await getDoc(quizDocRef);

        if (quizDoc.exists()) {
          const userDocRef = doc(db, "public-profiles", quizDoc.data().uid);
          const userDoc = await getDoc(userDocRef);
          setDisplayName(userDoc.data().displayName || "");
          setPhotoURL(userDoc.data().photoURL || "");
          setSenderUid(userDoc.data().uid || "");
        } else {
          console.log("User data not found.");
        }
      }
    };

    fetchSenderData();
  }, [fromQuizId]);

  return (
    <Layout background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}>
      <Container>
        <Row className="mt-30">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <QuestionBox style={{ flexDirection: "column" }}>
              <TextSm>{displayName} want's a vibe check!</TextSm>

              <H5 className="mb-20">what's your perfect first date?</H5>
              <div className="display-flex">
                {photoURL ? (
                  <ProPic src={photoURL} />
                ) : (
                  <SecPic>
                    <H3 className="mb-0">
                      {displayName ? displayName[0] : "?"}
                    </H3>
                  </SecPic>
                )}
                <CompareArrowsIcon className="mt-15" />
                {receiverPhoto ? (
                  <ProPic src={receiverPhoto} />
                ) : (
                  <SecPic>
                    <H3 className="mb-0">
                      {receiverName ? receiverName[0] : "?"}
                    </H3>
                  </SecPic>
                )}
              </div>
            </QuestionBox>
            <QuestionBox style={{ flexDirection: "column" }}>
              <H6 className="mb-0">what's your name?</H6>
              <TextField
                className="mt-20"
                fullWidth
                label="display name"
                variant="outlined"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px" },
                  "& .MuiInputLabel-root": { fontSize: "13px" },
                }}
              />
              <ButtonBlack
                className="mt-20"
                width="100%"
                onClick={authenticateAndSetDisplayName}
              >
                let's get started
              </ButtonBlack>
            </QuestionBox>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          ></Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LandingFD;
