import { Col, Container, Row } from "react-bootstrap";

import Layout from "../components/layout/layout";

import { ButtonBlack } from "../components/theme/Button";

import { TextField } from "@mui/material";
import { H6 } from "../components/theme/text/Headings";
import { useEffect, useState } from "react";
import { auth, db } from "../services/firebase.config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");
        setBio(data.bio || "");
      }
    };

    fetchProfileData();
  }, []);

  const handleUpdateProfile = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      alert("You need to be logged in to update your profile");
      return;
    }

    const profileDocRef = doc(db, "public-profiles", currentUser.uid);

    try {
      await setDoc(
        profileDocRef,
        {
          uid: currentUser.uid,
          username: username,
          photoURL: photoURL,
          displayName: displayName,
          bio: bio,
        },
        { merge: true } // Merge to add to the same document
      );

      navigate("/profile");
    } catch (error) {
      console.error("Error adding answer: ", error);
    }
  };

  return (
    <Layout>
      <Container>
        <Row className="mt-30 justify-content-center">
          <Col className="mt-30" style={{ padding: "0px 50px" }}>
            <H6>update your profile</H6>
            <TextField
              className="mt-30"
              fullWidth
              label="username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="profile picture URL"
              variant="outlined"
              value={photoURL}
              onChange={(e) => setPhotoURL(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="display name"
              variant="outlined"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <TextField
              fullWidth
              className="mt-30"
              label="bio"
              variant="outlined"
              multiline
              rows={2}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              sx={{
                "& .MuiInputBase-input": { fontSize: "13px" }, // Input text font size
                "& .MuiInputLabel-root": { fontSize: "13px" }, // Label font size
              }}
            />

            <ButtonBlack
              className="mt-50"
              width="100%"
              onClick={handleUpdateProfile}
            >
              save profile
            </ButtonBlack>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Settings;
