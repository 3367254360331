import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { auth, db } from "../services/firebase.config";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  serverTimestamp,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import Layout from "../components/layout/layout";
import { H6, TextSm } from "../components/theme/text/Headings";
import { QuizAnswerBox, QuestionBox } from "../components/theme/QuizBox";
import { White } from "../components/theme/colors";
import { useNavigate, useSearchParams } from "react-router-dom";

const QuestionTemplate = ({ question, answers, field, nextQuestionId }) => {
  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");
  const navigate = useNavigate();
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizDocId, setQuizDocId] = useState(null);

  const currentUser = auth.currentUser;

  useEffect(() => {
    const initializeQuizDoc = async () => {
      if (!currentUser) {
        alert("You need to be logged in to start the quiz.");
        return;
      }

      const userProfileRef = doc(db, "public-profiles", currentUser.uid);
      const userProfileDoc = await getDoc(userProfileRef);

      if (userProfileDoc.exists() && userProfileDoc.data().politicalQuizId) {
        // Use the existing quiz document if it exists
        setQuizDocId(userProfileDoc.data().politicalQuizId);
      } else {
        // Create a new quiz document if no `quizID` is present in the user's profile
        const newQuizRef = await addDoc(
          collection(db, "political-alignment-quiz"),
          {
            uid: currentUser.uid,
            answers: {},
            created: serverTimestamp(),
          }
        );
        setQuizDocId(newQuizRef.id);

        // Save the new `quizDocId` to the user's profile document
        await updateDoc(userProfileRef, { politicalQuizId: newQuizRef.id });
      }
    };

    initializeQuizDoc();
  }, [sharedId, currentUser]);

  const handleSelectQuiz = async (answer, percentage) => {
    if (!quizDocId) {
      console.error("Quiz document ID is not set. Cannot update answer.");
      return;
    }

    if (selectedQuiz === answer) {
      setSelectedQuiz(null);
      return;
    }

    setSelectedQuiz(answer);

    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("You need to be logged in to submit an answer.");
      return;
    }

    const quizDocRef = doc(db, "political-alignment-quiz", quizDocId);

    try {
      // Check if this is the final question
      const isFinalQuestion = !nextQuestionId;

      // Update the document with the selected answer for the current question
      await updateDoc(quizDocRef, {
        ...(isFinalQuestion && { status: "completed" }), // Mark quiz as completed if it's the final question
        [`answers.${question.id}`]: { answer, percentage, field },
      });

      // Additional shared quiz handling only if `sharedId` exists
      if (isFinalQuestion && sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        await updateDoc(sharedDocRef, {
          receiverQuizId: quizDocId, // Set the quizDocId as the receiverQuizId
        });

        // Retrieve the `shared-quizzes` document to get both UIDs
        const sharedDoc = await getDoc(sharedDocRef);
        if (sharedDoc.exists()) {
          const { senderUid, receiverUid } = sharedDoc.data();

          // Update each user's profile document with the shared quiz ID
          const senderProfileRef = doc(db, "public-profiles", senderUid);
          const receiverProfileRef = doc(db, "public-profiles", receiverUid);

          await Promise.all([
            updateDoc(senderProfileRef, {
              shared: arrayUnion(sharedId),
            }),
            updateDoc(receiverProfileRef, {
              shared: arrayUnion(sharedId),
            }),
          ]);
        }
      }

      // Navigate to the results page if it's the final question
      if (isFinalQuestion) {
        const nextUrl = sharedId
          ? `/political-alignment/results?shared=${sharedId}`
          : `/political-alignment/results`;
        navigate(nextUrl);
      } else {
        // Otherwise, go to the next question
        const nextUrl = sharedId
          ? `/political-alignment-question-${nextQuestionId}?shared=${sharedId}`
          : `/political-alignment-question-${nextQuestionId}`;
        navigate(nextUrl);
      }
    } catch (error) {
      console.error("Error adding answer:", error);
    }
  };

  return (
    <Layout background={"linear-gradient(90deg, #00F260, #0575E6)"}>
      <Container>
        <Row className="mt-50">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ offset: 1, span: 10 }}
            className="text-align-center"
          >
            <H6 className="mb-40" color="white">
              where do you stand politically?
            </H6>
            <QuestionBox>
              <div>
                <TextSm className="mt-10">Question {question.id}</TextSm>
                <H6 className="mt-10">{question.questionText}</H6>
              </div>
            </QuestionBox>

            {answers.map((answer, index) => (
              <Row key={index}>
                <Col>
                  <QuizAnswerBox
                    background={White}
                    selected={selectedQuiz === answer.copy}
                    anyselected={selectedQuiz !== null}
                    onClick={() =>
                      handleSelectQuiz(answer.answer, answer.percentage)
                    }
                  >
                    <TextSm>{answer.copy}</TextSm>
                  </QuizAnswerBox>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default QuestionTemplate;
