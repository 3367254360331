import { Col, Container, Row } from "react-bootstrap";
import Layout from "../../components/layout/layout";
import { H3, H5, H6, TextSm } from "../../components/theme/text/Headings";
import {
  BreakdownBody,
  BreakdownBottom,
  BreakdownContainer,
  BreakdownHeader,
  QuestionBox,
} from "../../components/theme/QuizBox";
import Axis from "../../components/theme/axis";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../services/firebase.config";
import styled from "styled-components";
import { Black } from "../../components/theme/colors";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SecPic } from "../First-Date-Quiz/LandingFD";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { ButtonBlack } from "../../components/theme/Button";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Black};
`;

const ResultsPA = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [quizId, setQuizId] = useState("");

  const [quizId2, setQuizId2] = useState("");
  const [displayName2, setDisplayName2] = useState("");
  const [photoURL2, setPhotoURL2] = useState("");
  const [username2, setUsername2] = useState("");

  const [searchParams] = useSearchParams();
  const sharedId = searchParams.get("shared");

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");

        setQuizId(data.politicalQuizId);
      }
    };

    fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const getSharedCollection = async () => {
      if (sharedId) {
        const sharedDocRef = doc(db, "shared-quizzes", sharedId);
        const sharedDoc = await getDoc(sharedDocRef);

        if (
          sharedDoc.data().senderUid === currentUser.uid &&
          sharedDoc.data().quiz === "political-alignment"
        ) {
          setQuizId2(sharedDoc.data().receiverQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().receiverUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        } else if (sharedDoc.data().senderUid !== currentUser.uid) {
          setQuizId(sharedDoc.data().senderQuizId);

          const profile2DocRef = doc(
            db,
            "public-profiles",
            sharedDoc.data().senderUid
          );
          const profile2Doc = await getDoc(profile2DocRef);

          if (profile2Doc.exists()) {
            const data = profile2Doc.data();
            setUsername2(data.username || "");
            setPhotoURL2(data.photoURL || "");
            setDisplayName2(data.displayName || "");
          }
        }
      }
    };
    getSharedCollection();
  }, [sharedId, currentUser.uid]);

  return (
    <Layout background={"linear-gradient(90deg, #00F260, #0575E6)"}>
      <Container>
        <Row className="mt-30">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <QuestionBox style={{ flexDirection: "column" }}>
              <TextSm>quiz results</TextSm>
              <H5 className="mb-20">what's your political alignment?</H5>

              {!sharedId ? (
                <>
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </>
              ) : (
                <div className="display-flex">
                  {photoURL ? (
                    <ProPic src={photoURL} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName ? displayName[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                  <CompareArrowsIcon className="mt-15" />
                  {photoURL2 ? (
                    <ProPic src={photoURL2} />
                  ) : (
                    <SecPic>
                      <H3 className="mb-0">
                        {displayName2 ? displayName2[0] : "?"}
                      </H3>
                    </SecPic>
                  )}
                </div>
              )}
            </QuestionBox>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col
            lg={{ offset: 4, span: 4 }}
            md={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            xs={{ span: 12 }}
            className="text-align-center"
          >
            <BreakdownContainer>
              <BreakdownHeader>
                <H6 className="mb-0">
                  score breakdown:{" "}
                  {username ? `@${username}` : `${displayName}`}{" "}
                  {sharedId && (
                    <> {username2 ? `+ @${username2}` : `+ ${displayName2}`}</>
                  )}
                </H6>
              </BreakdownHeader>

              <BreakdownBody>
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Socially Conservative"
                  rightLabel="Socially Liberal"
                  leftDescription="Values tradition and limited social change."
                  rightDescription="Supports equality and progressive policies."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="socialLib"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Economically Conservative"
                  rightLabel="Economically Liberal"
                  leftDescription="Supports minimal regulation and free markets."
                  rightDescription="Favors progressive regulation and social welfare programs."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="econLib"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Nationalism"
                  rightLabel="Globalism"
                  leftDescription="Prioritizes national interests and limited foreign intervention."
                  rightDescription="Advocates for international cooperation and shared solutions."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="globalism"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Energy Expansion"
                  rightLabel="Environmental Conservation"
                  leftDescription="Advocates for national energy independence."
                  rightDescription="Prioritizes transition to renewable energy."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="conservation"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Private Healthcare"
                  rightLabel="Public Healthcare"
                  leftDescription="Healthcare should go through the private sector."
                  rightDescription="Access to healthcare should be a right, funded through taxes."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="public"
                />
                <Axis
                  quizName="politicalAlignment"
                  quizId={quizId}
                  senderQuizId={quizId2}
                  leftLabel="Personal Accountability"
                  rightLabel="Social Support"
                  leftDescription="The individual is responsible for their welfare."
                  rightDescription="Government should provide a safety net for it’s citizens."
                  displayName={displayName}
                  profilePicture={photoURL}
                  profilePicture2={photoURL2}
                  displayName2={displayName2}
                  field="socialSupport"
                />
              </BreakdownBody>
            </BreakdownContainer>
          </Col>
        </Row>
        {(currentUser.isAnonymous || !currentUser) && (
          <Row>
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ span: 12 }}
              className="text-align-center"
            >
              <BreakdownBottom className="justify-content-center display-column">
                <H6 className="mb-20">👇 create an account 👇</H6>
                <ButtonBlack
                  className="mt-0"
                  width="80%"
                  onClick={() =>
                    navigate("/login", { state: { linkAnonymous: true } })
                  }
                >
                  save your answers
                </ButtonBlack>
              </BreakdownBottom>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default ResultsPA;
