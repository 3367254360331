import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { FilledInput, FormControl, InputLabel } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import logoImg from "../../images/logo/1.png";

import { H4, TextSm } from "../../components/theme/text/Headings";
import { ButtonBlack } from "../../components/theme/Button";
import { Section } from "../../components/layout/layout";

import {
  linkWithPhoneNumber,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../services/firebase.config";
import { useLocation, useNavigate } from "react-router-dom";

const Body = styled.div`
  height: 100vh;
  position: relative;
  padding-bottom: 60px; /* Add space to ensure the content doesn't overlap with the button bar */
`;

const Login = (currentUserState) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const [nextStep, setNextStep] = useState(false);

  // Detects if this is a login to convert an anonymous account
  const isAnonymousLinking = location.state?.linkAnonymous;

  const sendOtp = async () => {
    const appVerifier = window.recaptchaVerifier;

    try {
      const user = auth.currentUser;

      // If anonymous linking is required, use linkWithPhoneNumber
      if (isAnonymousLinking && user && user.isAnonymous) {
        const result = await linkWithPhoneNumber(
          user,
          phoneNumber,
          appVerifier
        );
        setConfirmationResult(result);
        setNextStep(true);
        console.log(
          "OTP sent to phone number for anonymous linking",
          phoneNumber
        );
      } else {
        // Regular phone sign-in for non-anonymous users
        const result = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier
        );
        setConfirmationResult(result);
        setNextStep(true);
        console.log(
          "OTP sent to phone number for regular sign-in",
          phoneNumber
        );
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const verifyOtp = async () => {
    try {
      const result = await confirmationResult.confirm(otp);
      const user = result.user;
      console.log("User signed in successfully:", user);
      navigate("/home");
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  useEffect(() => {
    if (currentUserState) {
      navigate("/home");
    }
  }, [currentUserState, navigate]);

  useEffect(() => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA solved", response);
          sendOtp();
        },
      }
    );
  });

  return (
    <div>
      <Body>
        <Section style={{ height: "90vh" }}>
          <Container>
            <Row style={{ paddingTop: "15vh" }}>
              <Col lg={{ offset: 4, span: 3 }}>
                <img
                  src={logoImg}
                  alt="Vibe Check Logo"
                  style={{ height: "25px" }}
                />
                <H4 className="mt-30">hi! ready for a vibe check?</H4>
                <TextSm className="mt-10">login or create account</TextSm>

                {!nextStep && (
                  <>
                    <FormControl variant="filled" className="mt-50" fullWidth>
                      <InputLabel htmlFor="phone">
                        enter your phone number
                      </InputLabel>
                      <FilledInput
                        id="phone"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {console.log("number", phoneNumber)}
                    </FormControl>

                    <ButtonBlack
                      width="100%"
                      className="mt-50"
                      onClick={sendOtp}
                    >
                      text me a code
                    </ButtonBlack>
                  </>
                )}

                {nextStep && (
                  <>
                    <FormControl variant="filled" className="mt-50" fullWidth>
                      <InputLabel htmlFor="phone">enter your OTP</InputLabel>
                      <FilledInput
                        id="otp"
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </FormControl>

                    <ButtonBlack
                      width="100%"
                      className="mt-50"
                      onClick={verifyOtp}
                    >
                      login
                    </ButtonBlack>
                  </>
                )}
              </Col>
            </Row>
            <div id="recaptcha-container"></div>
          </Container>
        </Section>
      </Body>
    </div>
  );
};

export default Login;
