import styled, { keyframes } from "styled-components";
import { White } from "../theme/colors";

// Define keyframes for moving the cards horizontally across the screen
const scrollCards = keyframes`
  0% {
    transform: translateX(0%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
`;

// Styled component for the container that holds the cards
export const CardBar = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 200px; /* Adjust height to fit the cards */
`;

// Styled component for the card container with scrolling animation
export const ScrollingCards = styled.div`
  display: flex;
  white-space: nowrap; /* Ensure cards stay in a single line */
  animation: ${scrollCards} 30s linear infinite; /* Adjust timing for speed */
`;

// Styled component for each individual card
export const CardContainer = styled.div`
  flex: 0 0 auto; /* Ensure cards don’t shrink */
  margin-right: 20px; /* Space between cards */
  background: ${(props) => props.background};
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const CardHeader = styled.div`
  font-family: "Protest Strike", serif;
  color: ${White};
  font-size: 20px;
  padding-bottom: 15px;
`;

export const Card = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  font-family: "Satoshi-Variable", sans-serif;
  font-weight: 600;
`;

// Modified QuestionCard component to handle multiple cards moving horizontally
const QuestionCard = (props) => {
  return (
    <CardContainer background={props.background}>
      <CardHeader>{props.header}</CardHeader>
      <Card>{props.children}</Card>
    </CardContainer>
  );
};

export default QuestionCard;
