import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuizIcon from "@mui/icons-material/Quiz";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";

import { Black05, Black09 } from "../theme/colors";
import { TextXsNorm } from "../theme/text/Headings";

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  font-size: 10px !important;
  color: ${Black05} !important; // Default color for icons
  &.Mui-selected {
    color: ${Black09} !important; // Color when selected
  }
`;

export default function LabelBottomNavigation() {
  const location = useLocation(); // Get current path
  const navigate = useNavigate(); // Navigation function

  const [value, setValue] = React.useState(location.pathname);

  // Sync state with current route
  React.useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue); // Navigate to the selected path
  };

  return (
    <BottomNavigation showLabels value={value} onChange={handleChange}>
      <StyledBottomNavigationAction
        label={<TextXsNorm>Quizzes</TextXsNorm>}
        value="/home"
        icon={<QuizIcon fontSize="small" className="mt-10" />}
      />
      <StyledBottomNavigationAction
        label={<TextXsNorm>Results</TextXsNorm>}
        value="/results"
        icon={<CollectionsBookmarkIcon fontSize="small" className="mt-10" />}
      />
      <StyledBottomNavigationAction
        label={<TextXsNorm>Profile</TextXsNorm>}
        value="/profile"
        icon={<AccountCircleIcon fontSize="small" className="mt-10" />}
      />
    </BottomNavigation>
  );
}
