import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/PAQuestionTemplate";

const QuestionPA9 = () => {
  const questionData = questionsData.find((q) => q.id === "9");
  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
      nextQuestionId={questionData.nextQuestionId}
    />
  );
};

export default QuestionPA9;
