import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import logoImg from "../images/logo/1.png";

import "bootstrap/dist/css/bootstrap.min.css";
import { Body, Section } from "../components/layout/layout";

const Confirmation = () => {
  return (
    <div>
      <Body>
        <Section style={{ height: "90vh" }}>
          <Container>
            <Row style={{ paddingTop: "15vh" }}>
              <Col style={{ textAlign: "center" }}>
                <img
                  src={logoImg}
                  alt="Night Notes Logo"
                  style={{ height: "200px", marginBottom: "30px" }}
                />
                <h1>Your Confirmed!</h1>
                <h4 style={{ marginTop: "30px" }}>
                  You've been added to our Apple app store waitlist! <br />
                  We'll send you a text when we launch our new app :).
                </h4>
                <Link to="/">
                  <Button variant="dark" style={{ marginTop: "50px" }}>
                    <h5 style={{ margin: "10px 15px 10px 15px" }}>
                      Go Back to Home
                    </h5>
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Section>
      </Body>
    </div>
  );
};

export default Confirmation;
