import { Col, Container, Row } from "react-bootstrap";

import Layout from "../components/layout/layout";

import { auth, db } from "../services/firebase.config";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";
import { ResultsBox } from "../components/theme/QuizBox";
import { H3, H6, Paragraph } from "../components/theme/text/Headings";
import styled from "styled-components";
import { SecPic } from "./First-Date-Quiz/LandingFD";
import { Black } from "../components/theme/colors";
import { Link } from "react-router-dom";

const ProPic = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid ${Black};
`;

const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Keep the text color inherited */
`;

const Results = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [sharedQuizzes, setSharedQuizzes] = useState([]);
  const [quizDetails, setQuizDetails] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const sharedQuizIds = profileDoc.data().shared || [];
        setSharedQuizzes(sharedQuizIds);
      }
    };

    fetchProfileData();
  }, [currentUser]);

  useEffect(() => {
    const fetchSharedQuizzes = async () => {
      if (!sharedQuizzes.length) return;

      const quizPromises = sharedQuizzes.map(async (quizId) => {
        const quizDocRef = doc(db, "shared-quizzes", quizId);
        const quizDoc = await getDoc(quizDocRef);

        if (quizDoc.exists()) {
          const quizData = quizDoc.data();
          const otherUserUid =
            quizData.senderUid === currentUser.uid
              ? quizData.receiverUid
              : quizData.senderUid;

          const otherUserProfileRef = doc(db, "public-profiles", otherUserUid);
          const otherUserProfileDoc = await getDoc(otherUserProfileRef);

          if (otherUserProfileDoc.exists()) {
            return {
              id: quizId,
              quizData,
              otherUserData: otherUserProfileDoc.data(),
            };
          }
        }
        return null;
      });

      const quizzes = (await Promise.all(quizPromises)).filter(Boolean);
      setQuizDetails(quizzes);
    };

    fetchSharedQuizzes();
  }, [sharedQuizzes, currentUser]);

  return (
    <Layout>
      {currentUser !== null && (
        <Container>
          <Row className="mt-30">
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ offset: 1, span: 10 }}
              className="text-align-center"
            >
              <H6 className="mb-20">results with your friends</H6>
              <Row>
                {quizDetails.length > 0 ? (
                  quizDetails.map((quiz) => (
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      key={quiz}
                      className="mb-3"
                    >
                      <StyledLink to={`/first-date/results?shared=${quiz.id}`}>
                        <ResultsBox
                          key={quiz}
                          background={
                            "linear-gradient(90deg, #fc4a1a, #f7b733)"
                          }
                        >
                          {quiz.otherUserData.photoURL ? (
                            <ProPic src={quiz.otherUserData.photoURL} />
                          ) : (
                            <SecPic>
                              <H3 className="mb-0">
                                {quiz.otherUserData.displayName
                                  ? quiz.otherUserData.displayName[0]
                                  : "?"}
                              </H3>
                            </SecPic>
                          )}
                          <H6
                            className="mt-10"
                            style={{ textDecoration: "none" }}
                          >
                            First Date
                          </H6>
                        </ResultsBox>
                      </StyledLink>
                    </Col>
                  ))
                ) : (
                  <Paragraph className="mt-100">
                    No results found. Send your friends a link from the home
                    page to see their responses here.
                  </Paragraph>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Results;
