import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ currentUserState, children }) {
  if (currentUserState === false) {
    return <Navigate to="/login" />;
  }

  return children;
}
