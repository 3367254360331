import { Container, Row, Col, Navbar, NavbarBrand, Nav } from "react-bootstrap";
import { D1, H3, H6, Span } from "../components/theme/text/Headings";

import "bootstrap/dist/css/bootstrap.min.css";
import { Black, White } from "../components/theme/colors";
import { ButtonWhite } from "../components/theme/Button";
import { Link } from "react-router-dom";
import QuestionCard, {
  CardBar,
  ScrollingCards,
} from "../components/landing/QuestionCard";

import logoImg from "../images/logo/2.png";

const backgroundStyle = {
  background: Black, // Example background
  height: "75vh",
  position: "relative",
  overflow: "hidden",
};

const textStyle = {
  padding: "20px",
  borderRadius: "10px",
  color: White,
};

const Landing = () => {
  return (
    <>
      <Navbar
        expand="lg"
        style={{
          backgroundColor: Black,
          border: "none",
          padding: "0 0 10px",
        }}
      >
        <Container>
          <NavbarBrand>
            <img
              src={logoImg}
              alt="Vibe Check Logo"
              style={{ height: "25px", marginTop: "20px" }}
            />
          </NavbarBrand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="custom-toggler mt-20"
          />
          <Navbar.Collapse className="justify-content-end text-align-right">
            <Nav className="justify-content-end mt-30">
              <Nav.Link href="#link" style={{ color: White }}>
                <H6>Privacy Policy</H6>
              </Nav.Link>
              <Nav.Link href="#link" style={{ color: White }}>
                <H6>Contact Us</H6>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div style={backgroundStyle}>
        <Container style={{ position: "relative" }}>
          {/* Header Section */}
          <Row style={{ paddingTop: "10vh", position: "relative", zIndex: 10 }}>
            <Col style={{ textAlign: "center" }}>
              <div style={textStyle}>
                <D1 color={White}>
                  send a link <br />
                  get a <Span color="yellow"> vibe check</Span>
                </D1>
                <H3 className="mt-20">
                  personality tests to get to know someone
                </H3>
                <Link to="/login">
                  <ButtonWhite className="mt-50">take a quiz</ButtonWhite>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <CardBar>
          <ScrollingCards>
            <QuestionCard
              header={"How Kinky Are You?"}
              background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}
            >
              I'm a Dom 👀
            </QuestionCard>

            <QuestionCard
              header={"What's an Ideal First Date?"}
              background={"linear-gradient(90deg, #FC5C7D, #6A82FB)"}
            >
              Coffee at the Park ☕️
            </QuestionCard>

            <QuestionCard
              header={"What's Your Love Language?"}
              background={"linear-gradient(90deg, #0082c8, #667db6)"}
            >
              Words of Affirmation 💌
            </QuestionCard>

            <QuestionCard
              header={"What's Your Biggest Red Flag?"}
              background={"linear-gradient(90deg, #00F260, #0575E6)"}
            >
              Ghosting 🛑
            </QuestionCard>

            <QuestionCard
              header={"Are you a top or bottom?"}
              background={"linear-gradient(90deg, #ff9966, #ff5e62)"}
            >
              TOP 🔝
            </QuestionCard>

            <QuestionCard
              header={"Sex on the First Date?"}
              background={"linear-gradient(90deg, #7F00FF, #E100FF)"}
            >
              Nope ✋🏼
            </QuestionCard>
            <QuestionCard
              header={"How Kinky Are You?"}
              background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}
            >
              I'm a Dom 👀
            </QuestionCard>

            <QuestionCard
              header={"What's an Ideal First Date?"}
              background={"linear-gradient(90deg, #FC5C7D, #6A82FB)"}
            >
              Coffee at the Park ☕️
            </QuestionCard>

            <QuestionCard
              header={"What's Your Love Language?"}
              background={"linear-gradient(90deg, #0082c8, #667db6)"}
            >
              Words of Affirmation 💌
            </QuestionCard>

            <QuestionCard
              header={"What's Your Biggest Red Flag?"}
              background={"linear-gradient(90deg, #00F260, #0575E6)"}
            >
              Ghosting 🛑
            </QuestionCard>

            <QuestionCard
              header={"Are you a top or bottom?"}
              background={"linear-gradient(90deg, #ff9966, #ff5e62)"}
            >
              TOP 🔝
            </QuestionCard>

            <QuestionCard
              header={"Sex on the First Date?"}
              background={"linear-gradient(90deg, #7F00FF, #E100FF)"}
            >
              Nope ✋🏼
            </QuestionCard>
            <QuestionCard
              header={"How Kinky Are You?"}
              background={"linear-gradient(90deg, #fc4a1a, #f7b733)"}
            >
              I'm a Dom 👀
            </QuestionCard>

            <QuestionCard
              header={"What's an Ideal First Date?"}
              background={"linear-gradient(90deg, #FC5C7D, #6A82FB)"}
            >
              Coffee at the Park ☕️
            </QuestionCard>

            <QuestionCard
              header={"What's Your Love Language?"}
              background={"linear-gradient(90deg, #0082c8, #667db6)"}
            >
              Words of Affirmation 💌
            </QuestionCard>

            <QuestionCard
              header={"What's Your Biggest Red Flag?"}
              background={"linear-gradient(90deg, #00F260, #0575E6)"}
            >
              Ghosting 🛑
            </QuestionCard>

            <QuestionCard
              header={"Are you a top or bottom?"}
              background={"linear-gradient(90deg, #ff9966, #ff5e62)"}
            >
              TOP 🔝
            </QuestionCard>

            <QuestionCard
              header={"Sex on the First Date?"}
              background={"linear-gradient(90deg, #7F00FF, #E100FF)"}
            >
              Nope ✋🏼
            </QuestionCard>
          </ScrollingCards>
        </CardBar>
      </div>
    </>
  );
};

export default Landing;
