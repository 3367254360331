// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDqVOcEfXrUlP-53iUILrcJLnCSJAiCdP4",
  authDomain: "vibe-check-c6433.firebaseapp.com",
  projectId: "vibe-check-c6433",
  storageBucket: "vibe-check-c6433.firebasestorage.app",
  messagingSenderId: "161052244771",
  appId: "1:161052244771:web:6e083d86e1f49327436a96",
  measurementId: "G-F0K8909YJ3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db };
