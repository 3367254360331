import styled from "styled-components";
import { White07, White, Black } from "./colors";

const QuizBox = styled.div`
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
  background: ${(props) =>
    props.selected || !props.anyselected ? props.background : `#fff5ef`};
  color: ${(props) => (props.selected || !props.anyselected ? White : Black)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.selected || !props.anyselected
      ? "0 0.2rem 0.8rem rgba(0, 0, 0, 0.24)"
      : "none"};
  cursor: pointer;
  padding: 0 20px;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export default QuizBox;

export const AnswerBox = styled.div`
  height: 150px;
  width: 100%;
  margin-bottom: 20px;
  background: ${(props) =>
    props.selected || !props.anyselected ? props.background : White07};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: background 0.5s ease, background-color 0.5s ease;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export const QuestionBox = styled.div`
  min-height: 100px;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px 25px;
  display: flex;
  background: ${(props) => (props.background ? props.background : "white")};
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
`;

export const QuizAnswerBox = styled.div`
  min-height: 50px;
  width: 100%;
  margin-bottom: 20px;
  background: ${(props) =>
    props.selected || !props.anyselected ? props.background : White07};
  display: flex;
  justify-content: center;
  padding: 15px 25px;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: background 0.5s ease, background-color 0.5s ease;
  &:hover {
    opacity: ${(props) => (props.selected ? "1" : "0.8")};
  }
`;

export const BreakdownContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  border-radius: 0 0 5px 5px !important;
`;

export const BreakdownHeader = styled.div`
  text-align: left;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
  width: 100%;
  background: ${Black};
  color: ${White};
  min-height: 30px;
`;

export const BreakdownBody = styled.div`
  min-height: 300px;
  padding: 10px 20px;
  width: 100%;
  min-height: 300px;
  background-color: ${White};
  border-radius: 0 0 5px 5px !important;
`;

export const BreakdownBottom = styled.div`
  text-align: left;
  padding: 25px 15px;
  border-radius: 5px;
  width: 100%;
  background-color: ${White};
  min-height: 30px;
  margin-bottom: 50px;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.24);
`;

export const ResultsBox = styled.div`
  text-align: left;
  padding: 25px 15px;
  border-radius: 5px;
  width: 100%;
  min-height: 150px;
  background: ${(props) => (props.background ? props.background : `#fff5ef`)};
  color: ${White};
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;
