import React from "react";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";

import logoImg from "../../images/logo/v-white.png";

const NavbarCont = styled(Navbar)`
  background-color: white;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CenteredLogo = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center; /* This will center the logo horizontally */
  align-items: center; /* This will center the logo vertically */
`;

function NavbarComp() {
  return (
    <NavbarCont>
      <CenteredLogo>
        <img src={logoImg} alt="Vibe Check Logo" style={{ height: "50px" }} />
      </CenteredLogo>
    </NavbarCont>
  );
}

export default NavbarComp;
