import questionsData from "./QuestionData";
import QuestionTemplate from "../../templates/FDQuestionTemplate";

const QuestionFourFD = () => {
  const questionData = questionsData.find((q) => q.id === "4");
  return (
    <QuestionTemplate
      question={questionData}
      answers={questionData.answers}
      field={questionData.field}
      nextQuestionId={questionData.nextQuestionId}
    />
  );
};

export default QuestionFourFD;
