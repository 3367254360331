import styled from "styled-components";
import React, { useEffect, useState } from "react";
import LabelBottomNavigation from "./BottomNavigation";
import { Paper } from "@mui/material";
import NavbarComp from "./NavbarComp";
import { auth } from "../../services/firebase.config";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.background};
`;

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows the layout to expand and manage space */
`;

export const Body = styled.div`
  position: relative;
  padding-bottom: 60px; /* Add space to ensure the content doesn't overlap with the button bar */
`;

export const Section = styled.div`
  min-height: 25vh;
  padding: 50px 10px 50px;
`;

function Layout(props) {
  const [currentUserState, setCurrentUserState] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && !user.isAnonymous) {
        setCurrentUserState(true);
      } else {
        setCurrentUserState(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Wrapper background={props.background}>
      <NavbarComp />
      <StyledLayout>
        <Body>{props.children}</Body>
      </StyledLayout>
      {currentUserState ? (
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <LabelBottomNavigation />
        </Paper>
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

export default Layout;
