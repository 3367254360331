import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import { auth } from "./services/firebase.config";
import ProtectedRoute from "./services/ProtectedRoute";

import Landing from "./pages/Landing";
import Login from "./pages/Login/Login";
import Confirmation from "./pages/Confirmation";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Results from "./pages/Results";

import LandingFD from "./pages/First-Date-Quiz/LandingFD";
import QuestionOneFD from "./pages/First-Date-Quiz/QuestionFD1";
import QuestionTwoFD from "./pages/First-Date-Quiz/QuestionFD2";
import QuestionThreeFD from "./pages/First-Date-Quiz/QuestionFD3";
import QuestionFourFD from "./pages/First-Date-Quiz/QuestionFD4";
import QuestionFiveFD from "./pages/First-Date-Quiz/QuestionFD5";
import QuestionSixFD from "./pages/First-Date-Quiz/QuestionFD6";
import QuestionSevenFD from "./pages/First-Date-Quiz/QuestionFD7";
import QuestionEightFD from "./pages/First-Date-Quiz/QuestionFD8";
import QuestionNineFD from "./pages/First-Date-Quiz/QuestionFD9";
import QuestionTenFD from "./pages/First-Date-Quiz/QuestionFD10";
import QuestionElevenFD from "./pages/First-Date-Quiz/QuestionFD11";
import Question12FD from "./pages/First-Date-Quiz/QuestionFD12";
import Question13FD from "./pages/First-Date-Quiz/QuestionFD13";
import Question14FD from "./pages/First-Date-Quiz/QuestionFD14";
import Question15FD from "./pages/First-Date-Quiz/QuestionFD15";
import QuestionFD16 from "./pages/First-Date-Quiz/QuestionFD16";
import Question17FD from "./pages/First-Date-Quiz/QuestionFD17";
import Question18FD from "./pages/First-Date-Quiz/QuestionFD18";
import ResultsFD from "./pages/First-Date-Quiz/ResultsFD";

import ResultsPA from "./pages/Political-Alignment-Quiz/ResultsPA";
import QuestionPA1 from "./pages/Political-Alignment-Quiz/QuestionPA1";
import QuestionPA2 from "./pages/Political-Alignment-Quiz/QuestionPA2";
import QuestionPA3 from "./pages/Political-Alignment-Quiz/QuestionPA3";
import QuestionPA4 from "./pages/Political-Alignment-Quiz/QuestionPA4";
import QuestionPA5 from "./pages/Political-Alignment-Quiz/QuestionPA5";
import QuestionPA6 from "./pages/Political-Alignment-Quiz/QuestionPA6";
import QuestionPA7 from "./pages/Political-Alignment-Quiz/QuestionPA7";
import QuestionPA8 from "./pages/Political-Alignment-Quiz/QuestionPA8";
import QuestionPA9 from "./pages/Political-Alignment-Quiz/QuestionPA9";
import QuestionPA10 from "./pages/Political-Alignment-Quiz/QuestionPA10";
import QuestionPA11 from "./pages/Political-Alignment-Quiz/QuestionPA11";
import QuestionPA12 from "./pages/Political-Alignment-Quiz/QuestionPA12";
import QuestionPA13 from "./pages/Political-Alignment-Quiz/QuestionPA13";
import QuestionPA14 from "./pages/Political-Alignment-Quiz/QuestionPA14";
import QuestionPA15 from "./pages/Political-Alignment-Quiz/QuestionPA15";
import QuestionPA16 from "./pages/Political-Alignment-Quiz/QuestionPA16";
import QuestionPA17 from "./pages/Political-Alignment-Quiz/QuestionPA17";
import QuestionPA18 from "./pages/Political-Alignment-Quiz/QuestionPA18";

export default function App() {
  const [currentUserState, setCurrentUserState] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && !user.isAnonymous) {
        setCurrentUserState(true);
      } else {
        setCurrentUserState(false);
      }
    });

    return () => unsubscribe();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route
        path="/login"
        element={<Login currentUserState={currentUserState} />}
      />

      {/* Protect these routes */}
      <Route
        path="/home"
        element={
          <ProtectedRoute currentUserState={currentUserState}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/results"
        element={
          <ProtectedRoute currentUserState={currentUserState}>
            <Results />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute currentUserState={currentUserState}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute currentUserState={currentUserState}>
            <Settings />
          </ProtectedRoute>
        }
      />

      <Route path="/political-alignment/results" element={<ResultsPA />} />
      <Route path="/political-alignment-question-1" element={<QuestionPA1 />} />
      <Route path="/political-alignment-question-2" element={<QuestionPA2 />} />
      <Route path="/political-alignment-question-3" element={<QuestionPA3 />} />
      <Route path="/political-alignment-question-4" element={<QuestionPA4 />} />
      <Route path="/political-alignment-question-5" element={<QuestionPA5 />} />
      <Route path="/political-alignment-question-6" element={<QuestionPA6 />} />
      <Route path="/political-alignment-question-7" element={<QuestionPA7 />} />
      <Route path="/political-alignment-question-8" element={<QuestionPA8 />} />
      <Route path="/political-alignment-question-9" element={<QuestionPA9 />} />
      <Route
        path="/political-alignment-question-10"
        element={<QuestionPA10 />}
      />
      <Route
        path="/political-alignment-question-11"
        element={<QuestionPA11 />}
      />
      <Route
        path="/political-alignment-question-12"
        element={<QuestionPA12 />}
      />
      <Route
        path="/political-alignment-question-13"
        element={<QuestionPA13 />}
      />
      <Route
        path="/political-alignment-question-14"
        element={<QuestionPA14 />}
      />
      <Route
        path="/political-alignment-question-15"
        element={<QuestionPA15 />}
      />
      <Route
        path="/political-alignment-question-16"
        element={<QuestionPA16 />}
      />
      <Route
        path="/political-alignment-question-17"
        element={<QuestionPA17 />}
      />
      <Route
        path="/political-alignment-question-18"
        element={<QuestionPA18 />}
      />

      <Route path="/first-date" element={<LandingFD />} />
      <Route path="/first-date/results" element={<ResultsFD />} />
      <Route path="/first-date-question-1" element={<QuestionOneFD />} />
      <Route path="/first-date-question-2" element={<QuestionTwoFD />} />
      <Route path="/first-date-question-3" element={<QuestionThreeFD />} />
      <Route path="/first-date-question-4" element={<QuestionFourFD />} />
      <Route path="/first-date-question-5" element={<QuestionFiveFD />} />
      <Route path="/first-date-question-6" element={<QuestionSixFD />} />
      <Route path="/first-date-question-7" element={<QuestionSevenFD />} />
      <Route path="/first-date-question-8" element={<QuestionEightFD />} />
      <Route path="/first-date-question-9" element={<QuestionNineFD />} />
      <Route path="/first-date-question-10" element={<QuestionTenFD />} />
      <Route path="/first-date-question-11" element={<QuestionElevenFD />} />
      <Route path="/first-date-question-12" element={<Question12FD />} />
      <Route path="/first-date-question-13" element={<Question13FD />} />
      <Route path="/first-date-question-14" element={<Question14FD />} />
      <Route path="/first-date-question-15" element={<Question15FD />} />
      <Route path="/first-date-question-16" element={<QuestionFD16 />} />
      <Route path="/first-date-question-17" element={<Question17FD />} />
      <Route path="/first-date-question-18" element={<Question18FD />} />

      <Route path="/waitlist-confirmation" element={<Confirmation />} />
    </Routes>
  );
}
