import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import Layout from "../components/layout/layout";
import { Black, Black09, Gray, White } from "../components/theme/colors";
import { H1, H5, TextSm, TextXs } from "../components/theme/text/Headings";
import { auth, db } from "../services/firebase.config";
import { useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ButtonBlack, ButtonOutlineBlack } from "../components/theme/Button";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const ProPic = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50px;
  border: 3px solid ${Black};
`;

const SecPic = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 50px;
  border: 3px solid ${Black};
  background-color: ${Black09};
  margin-top: 3px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: ${White};
`;

const InfoBox = styled.div`
  background-color: #fff5ef;
  border-radius: 10px;
  padding: 15px 30px;
  margin: 10px 0;
  width: 100%;
  flex-direction: column;
  color: ${Black};
`;

const Profile = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");

  const [username, setUsername] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!currentUser) return;

      const profileDocRef = doc(db, "public-profiles", currentUser.uid);
      const profileDoc = await getDoc(profileDocRef);

      if (profileDoc.exists()) {
        const data = profileDoc.data();
        setUsername(data.username || "");
        setPhotoURL(data.photoURL || "");
        setDisplayName(data.displayName || "");
        setBio(data.bio || "");
      }
    };

    fetchProfileData();
  }, [currentUser]);

  const handleSignOut = async () => {
    try {
      const result = await signOut(auth);
      console.log("sign out occured", result);
      navigate("/login");
    } catch (error) {
      console.log("sign out error", error);
    }
  };

  return (
    <Layout>
      {currentUser !== null && (
        <Container>
          <Row className="mt-30">
            <Col
              lg={{ offset: 4, span: 4 }}
              md={{ offset: 3, span: 6 }}
              sm={{ offset: 2, span: 8 }}
              xs={{ offset: 1, span: 10 }}
            >
              <InfoBox className="text-align-center justify-content-center">
                {photoURL ? (
                  <ProPic src={photoURL} />
                ) : (
                  <SecPic>
                    <H1 className="mb-0">
                      {displayName ? displayName[0] : "?"}
                    </H1>
                  </SecPic>
                )}
                <H5 className="mt-20">
                  {displayName ? displayName : "your name"}
                </H5>
                <TextSm>{username ? `@${username}` : "no username"}</TextSm>
                <TextXs color={Gray} className="mt-15">
                  {bio
                    ? bio
                    : "please edit your profile and add a bio, display name, and username"}
                </TextXs>
              </InfoBox>
              <Col className="mt-10">
                <Link to="/settings">
                  <ButtonOutlineBlack className="mt-20" width="100%">
                    edit profile
                  </ButtonOutlineBlack>
                </Link>
                <ButtonBlack
                  width="100%"
                  className="mt-20"
                  onClick={handleSignOut}
                >
                  sign out
                </ButtonBlack>
              </Col>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Profile;
